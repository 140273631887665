@import '../../variables.scss';

.start-info-component {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: $background-grey-out;

    .dialog {
        position: absolute;
        max-width: 90%;
        box-sizing: border-box;
        width: 452px;
        height: auto;
        background-color: $base-color;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 16px 24px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
            font-size: 25px;
            letter-spacing: 0px;
            color: $text-color-two;
            font-weight: 600;
            text-align: center;
            margin: 0px;
            margin-bottom: 14px;
        }

        p {
            margin: 0px 0px 14px 0px;
            color: $text-color-two;
            font-size: 15px;
            letter-spacing: 0.5px;
            text-align: center;
            line-height: 1.3;
        }

        a {
            padding-top: 16px;
            padding-bottom: 8px;
            color: $grey-one;
        }
    }
}
