@import '../../variables.scss';

.onboarding-highlighter-rectangle {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
    transition: top, left, height, width;
    transition-duration: 0s;
    z-index: 998;
    pointer-events: none;
}

.onboarding-box-shadow-highlight {
    box-shadow: 0 0 0 99999px $background-grey-out;
}

.onboarding-dark-area {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $background-grey-out;
    mix-blend-mode: multiply;
    pointer-events: none;
}

.onboarding-click-blocker {
    z-index: 1000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.onboarding-pointer {
    z-index: 999;
    position: absolute;
    background-color: $grey-three_25;
    border: solid 1px #d6d6d6;
    border-radius: 50%;
    margin-left: -15px;
    top: 4px;
    width: 50px;
    height: 50px;
    transition: top, left;
    transition-duration: 1s;
}

.pointer-animation {
    animation: pointer-click 0.5s steps(3, end) forwards;
}

@keyframes pointer-click {
    from {
        background-color: $grey-three_25;
    }
    5% {
        background-color: $grey-three_50;
    }
    75% {
        background-color: $grey-three_50;
    }
    to {
        background-color: $grey-three_25;
    }
}
