.sp-preview {
    position: relative;
    display: flex;
    align-items: center;
    width: 20%;
    border: solid 1px $text-color_60;
    margin-top: 4px;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 4px;
    &:hover .sp-preview-btns {
        visibility: visible;
    }

    &-icon {
        min-width: 20px;
        height: 20px;
        margin-right: 6px;
    }

    &-content {
        flex-grow: 2;
        font-size: 12px;
        &-loading {
            width: 100%;
            text-align: center;
        }
    }

    &-btns {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        cursor: pointer;
    }
}
