@import '../../../variables.scss';

.go-to-submenu-component {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 68px;
    background-color: $primary-color_10;
    padding: 0 30px;
    font-size: 14px;
    letter-spacing: 0.07px;
    line-height: 19px;
}
