@import '../../variables.scss';

.social-share {
    .buttons {
        display: flex;
        justify-content: center;
        margin: 45px auto 20px auto;

        a:first-child {
            margin-right: 53px;
        }

        a:last-child {
            margin-left: 53px;
        }

        a {
            text-decoration: none;
            text-align: center;

            .share-text {
                font-size: 14px;
                font-weight: 600;
                color: $text-color;
                margin: 6px auto 0 auto;
                text-transform: uppercase;
            }
        }
    }

    .message {
        text-align: center;
        color: green;
        margin: 0;
    }
}
