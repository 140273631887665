@import '../../../variables.scss';

$footer-height: 56px;

.footer {
    display: flex;
    justify-content: space-between;
    background-color: $base-color;
    box-sizing: border-box;
    height: $footer-height;
    min-height: $footer-height;
    z-index: 2000;
    transition: transform 0.5s ease-in-out;
    border-top: 1px solid $grey-four;

    &.hidden {
        transform: translate3d(0, $footer-height, 0);
    }
    &-right {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        color: $base-color;
        width: 48%;
        background-color: $primary-color;
        z-index: 5;

        &:hover {
            background-color: $primary-color-hover;
            &:after {
                background-color: $primary-color-hover;
            }
        }

        &::after {
            content: '';
            width: 26px;
            height: 100%;
            background-color: $primary-color;
            position: absolute;
            top: 0;
            left: -12px;
            transform: skewX(-22deg);
        }

        &-caret {
            display: inline-flex;
        }

        &-caret img {
            transform: rotate(90deg);
            filter: brightness(0) invert(1); // TODO: talk about handling svgs
            height: 5px;
            width: 5px;
            margin-left: 6px;
        }
    }

    &-left {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        margin-left: $margin-large;
        margin-top: 9px;
        white-space: normal;

        &-tax-hint {
            font-size: 12px;
        }

        &-discount-sign {
            font-size: 14px;
            position: absolute;
            background-color: $discount;
            height: 21px;
            bottom: 40px;
            width: 77px;
            color: white;
            left: 0px;
            text-align: center;

            &::after {
                position: absolute;
                width: 10px;
                height: 100%;
                content: '';
                right: -5px;
                background-color: $discount;
                transform: skewX(-23deg);
            }
            &-text {
                vertical-align: middle;
                width: 100%;
                height: 100%;
            }
        }

        &-discount-original-price {
            text-decoration: line-through;
            color: $grey-three;
        }

        &-srp {
            margin-right: 4px;
            font-weight: 700;
        }
    }
}
