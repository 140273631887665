@import '../../variables.scss';

.size-info-component {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(#000, 0.45);

    .dialog {
        position: absolute;
        max-width: 90%;
        box-sizing: border-box;
        width: 400px;
        height: auto;
        background-color: $base-color;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 26px 30px;

        .cancel-button {
            position: absolute;
            top: 4px;
            right: 0px;
            padding: 8px 12px;
            img {
                height: 14px;
            }
        }

        p {
            margin: 0px;
            color: $text-color-two;
            font-size: 15px;
            font-weight: 300;
            letter-spacing: 0.5px;
            text-align: center;
        }
    }
}
