.menu-header-content {
    &-back-button {
        height: 100%;
        display: flex;
        width: 50px;
        align-items: center;
        justify-content: center;
        background-color: $primary-color;
    }

    &-label {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: $text-color;
        text-transform: uppercase;
        text-align: center;
        width: 85%;
    }
}
