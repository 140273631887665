@import '../../../variables.scss';

$circle-size-header: 80px;

.sp-modal-header {
    font-size: 20px;
    text-align: center;
    margin-bottom: 22px;
    overflow: visible;
    letter-spacing: 0.5px;

    &.success:before {
        position: absolute;
        content: '';
        top: -($circle-size-header / 2);
        left: 0;
        right: 0;
        margin: auto;
        background-image: url('/assets/icons/common/checkmark.svg');
        background-size: ($circle-size-header / 2.5);
        background-color: $primary-color;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        width: $circle-size-header;
        height: $circle-size-header;
    }
}
