.social-share-modal {
    .header {
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        margin-top: 10px;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        padding: 24px;

        img {
            width: 25px;
            height: 25px;
        }
    }
}
