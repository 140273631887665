@import '../../../variables.scss';

.config-option-measurement {
    &-header {
        background-color: $base-color;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: 0.5px;
        text-align: left;
        padding: 10px 31px;
        border-top: 1px solid $grey-four;
    }
    &-content {
        display: flex;
        overflow: auto;
        align-content: flex-start;
        flex-wrap: wrap;
        flex-grow: 2;
        padding: 26px 18px 26px 32px;
        margin-bottom: 67px;
    }
}
