@import '../../../variables.scss';

.mount-info-page {
    font-size: 20px;
    padding: 36px 40px 36px 32px;
    letter-spacing: 0.7px;
    line-height: 30px;

    &-info-text {
        padding-bottom: 30px;
    }

    .configure-series-link {
        text-decoration: underline;

        &-caret {
            text-decoration: none;
            transform: translate(6px, -3px) rotate(90deg);
            width: 12px;
            height: 5px;
            mask: url('../../../assets/icons/menu/caret.svg');
            background-color: $primary-color;
            mask-size: 100% auto;
        }
    }
}
