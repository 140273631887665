@import '../../variables.scss';
@import './config-option-item/ConfigOptionItem.component.scss';
@import './config-option-page/ConfigOptionPage.component.scss';
@import './ShowerBaseMenuOption/ShowerBaseMenuOption.component.scss';

$optionPickerBodyHeight: 100px;
.sp-config-option {
    &-body {
        overflow: scroll;
        display: flex;
        flex-wrap: wrap;
        margin: $margin-large 0;
        justify-content: flex-start;
        width: 100%;
        height: 0;

        &::-webkit-scrollbar {
            display: none;
        }

        &.open {
            height: calc(100vh - 266px);
            margin: 0;
        }
    }
}

@keyframes openAnimation {
    from {
        height: 0;
    }
    to {
        height: fit-content;
    }
}

@keyframes closeAnimation {
    from {
        height: fit-content;
    }
    to {
        height: 0;
    }
}
