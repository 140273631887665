@import '../../../../../variables.scss';

.shower-base-size-input {
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .label {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.45px;
        text-transform: uppercase;
    }

    &-value-border {
        box-sizing: border-box;
        border: transparent 1px solid;
    }

    &.error &-value-border {
        border: $error-red 1px solid;
    }

    &-wrapper {
        display: flex;
        align-items: center;
    }

    & &-value-input {
        text-align: right;
        position: relative;
        padding: 0;
        padding-right: 93px;
        width: calc(202px - 93px);
        max-width: calc(202px - 93px);
        height: 46px;
        font-size: 25px;
        color: $text-color;
        background-color: $base-color;
        border: solid 1px $grey-three_50;
        box-shadow: inset 0 5px 7px 0 $grey-three_40;
        color: $text-color;

        &:focus {
            outline: inherit;
            border: solid 1px $grey-three_40;
        }
    }

    &-error {
        position: absolute;
        top: -16px;
        color: $error-red;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.45px;
    }

    &-reset-input {
        background-color: $grey-three_20;
        border-radius: 100px;
        margin-left: 19px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: $grey-three_30;
        }

        svg {
            width: 10px;
            height: 10px;
            &:hover {
                fill: black;
            }
        }
    }

    &-unit {
        position: absolute;
        font-size: 20px;
        right: 98px;
    }
}
