@import '../../variables.scss';

.legal {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 17px 0px;

    :last-child {
        padding-right: 21px;
    }

    a {
        color: $text-color_60;
        font-size: 16px;
        font-weight: 600;
        padding-left: 6px;
        padding-right: 6px;
    }
}
