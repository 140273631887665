@import '../../variables.scss';

.product-item {
    margin: $margin-large;
    display: flex;
    flex-direction: column;
    margin: 2px 0;
    padding: 7.5px $padding-large;

    &.surplus {
        background-color: $primary-color_10;
    }

    &-label {
        font-size: 12px;
        font-size: 16px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: $text-color;
        text-transform: uppercase;
    }

    &-content {
        position: relative;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: $text-color-two;

        &-surplus {
            position: absolute;
            top: -20px;
            right: 0;
            text-transform: uppercase;
            font-size: 12px;
            letter-spacing: 0.45px;
            color: $primary-color;
        }

        .product-item-description.gold {
            color: $primary-color;
        }
    }
}
