@import '../../../variables.scss';

$product-overview-header-height: 56px;
$footer-height: 56px;

.product-overview {
    position: absolute;
    top: calc(100% - (#{$product-overview-header-height} + #{$footer-height}));
    left: 0;
    right: 0;
    bottom: 0;
    transition: top 0.5s ease-in-out;

    &-caret-wrapper {
        cursor: pointer;
    }

    &.open {
        position: absolute;
        top: 0;
    }

    &.open &-header {
        border-top: 1px solid $grey-four;
        &-label {
            transform: translateX(18px);
        }
    }

    &.open &-caret {
        transform: rotate(180deg);
    }

    &-header {
        display: flex;
        justify-content: space-between;
        height: $product-overview-header-height;
        box-sizing: border-box;
        padding-top: 16px;
        padding-left: 20px;
        padding-right: 18px;
        background-color: $base-color;
        color: $text-color;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        text-transform: uppercase;

        cursor: pointer;
        transition: background-color 0.2s ease;
        box-shadow: -2px 4px 10px 0 $grey-three_70;
        &-label {
            transition: transform 0.5s ease-in-out;
            transform: translateX(0);
        }
    }

    &-content {
        background-color: $base-color;
        height: calc(100% - #{$product-overview-header-height});
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &-product-list {
            padding-left: 0;
            padding-bottom: $padding-large;
            overflow: scroll;
            margin: 0;

            &-series {
                font-size: 16px;
                text-transform: uppercase;
                color: $primary-color;
                margin-top: 4px;
                margin-left: $margin-large;
                position: absolute;
                display: inline-block;
                top: 38px;
            }
        }

        &-srp-footer {
            box-sizing: border-box;
            box-shadow: -2px 4px 10px 0 $grey-three_70;

            &-inner-container {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;
            }
            &-tax-hint {
                color: $text-color;
                font-size: 12px;
                text-align: end;
                padding-right: 1px;
                font-weight: normal;
            }
            &-price {
                box-sizing: border-box;
                display: flex;
                padding: 0 18px 8px 20px;
                height: 71px;
                align-items: flex-end;
                background-color: $primary-color_20;
                font-size: 18px;
                color: $text-color;
                font-weight: 600;

                &.discount {
                    align-items: flex-start;
                    padding-top: 16px;
                    height: auto;
                    padding-bottom: 16px;
                }

                .price-cut {
                    color: $grey-three;
                    text-decoration: line-through;
                    padding-bottom: 5px;
                }

                &-label-with-info {
                    display: flex;
                    align-items: center;
                }
                &-label {
                    text-transform: uppercase;
                    margin-right: 8px;
                }
            }
        }

        &-btn {
            background-color: $primary-color;
            padding: $padding-medium;
            margin: $margin-large 18px 30px 20px;
            border: none;
            cursor: pointer;
            color: $base-color;
            height: 56px;
            font-size: 18px;
            font-weight: 600;
            width: calc(100% - 20px - 18px);

            &:hover {
                background-color: $primary-color-hover;
            }
        }
    }
}
