@import '../../variables.scss';

.privacy-disclaimer-component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $background-grey-out;
    z-index: 10010;
}

.privacy-disclaimer {
    display: flex;
    flex-flow: column wrap;
    box-sizing: border-box;
    color: #fff;
    padding: 20px 20px 0px 20px;
    position: fixed;
    top: 40%;
    left: 50%;
    background-color: $primary-color;
    width: 453px;
    height: 286px;
    font-size: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 1.43;
    transform: translate(-50%, -50%);

    &.more-info {
        top: 50%;
        height: auto;
    }

    a {
        color: #fff;
        text-decoration: none;
    }

    &-headline {
        font-weight: 600;
        padding-bottom: 16px;
        font-size: 16px;
    }

    &-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        padding: 20px;
        text-align: center;
        padding: 0;
        margin-top: 20px;
        align-items: center;
        box-sizing: border-box;

        a,
        span {
            text-decoration: underline;
        }
    }

    &-button {
        display: block;
        text-align: center;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
        margin-bottom: 18px;
    }

    .button-yes {
        background-color: $primary-color;
        font-weight: 600;
        border: 2px solid #fff;
        padding: 12px;
        width: 398px;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 16px;
        box-sizing: border-box;
    }

    .button-no {
        margin-bottom: 8px;
    }

    .info-dienst {
        width: 100%;
        margin: 15px auto 15px auto;
        background-color: #fff;

        max-height: calc(100vh - 200px);
        overflow-y: auto;
        color: #000;
        line-height: 120%;
    }

    &-footer {
        font-weight: 600;
        flex-flow: column nowrap;
        margin-top: auto;
        margin-bottom: 10px;
    }

    .hyperlinkfe {
        display: block;
        flex: 1;
        text-align: center;
        text-decoration: none;

        a {
            color: #fff;
            white-space: nowrap;
        }
    }

    .button-info {
        display: block;
        flex: 1;
        text-align: center;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
    }

    .lessinfos {
        display: none;
    }
}
