@import '../../../variables.scss';

.config-option-page {
    &-submenu {
        min-height: 57px;
        background-color: $base-color;
        border-top: 1px solid $grey-four;
        display: flex;
        align-items: center;
        // TODO: This is only working, as long as the Submenu Headers fit into the menu width
        // To fix this InfoDialogComponent should mount Popup in Portal
        // overflow-x: auto;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .info-dialog-component {
            margin-left: 8px;
        }

        &-link {
            cursor: pointer;
            letter-spacing: 0.45px;
            line-height: 40px;
            flex-grow: 2;
            text-align: center;
            font-size: 18px;
            padding: 8px;
            padding-bottom: 4px;
            border-bottom: 4px solid $base-color;
            white-space: nowrap;

            &.selected {
                font-weight: 600;
                border-bottom-color: $primary-color;
            }

            &.disabled {
                cursor: unset;
                color: $grey-three;
            }
        }
    }

    &-content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        padding: 0px;
        margin-bottom: 67px;
        overflow: auto;
        overflow-x: hidden;
        scrollbar-width: thin;

        &-config-items-window {
            &.model {
                overflow-y: scroll;
                overflow-x: hidden;
            }

            &-content {
                display: flex;
                align-content: flex-start;
                flex-wrap: wrap;
                padding: $config-option-content-items-distance-top $config-option-content-items-distance-right
                    $config-option-content-items-distance-bottom $config-option-content-items-distance-left;
            }
        }
    }
}
