@import '../../variables.scss';
@import './components/DropzonePreview.scss';
@import './components/DropzoneIcon.component.scss';
@import './components/DropzoneLoading.component.scss';

.ReactModal__Content {
    display: flex;
    flex-direction: column;
    top: 50% !important;
    transform: translateY(-50%);
    bottom: revert !important;
    max-height: 700px;
    overflow: auto;
    border-radius: 0 !important;
}

.send-offer-form {
    .spacer {
        width: 28px !important;
        margin: 0;
    }

    .sp-modal-header {
        color: $text-color;
        margin-bottom: 0px;
    }

    .sp-modal-content {
        height: auto;
        margin: 18px 0px 38px 0px;
    }

    .send-offer-form-content {
        .row {
            display: flex;
            align-items: center;
            justify-content: center;

            > div:not(.dzu-dropzone):not(.column) {
                position: relative;
                display: flex;
                margin: 8px 0px;
                width: 50%;
                align-items: center;
                justify-content: center;

                p {
                    position: absolute;
                    content: '';
                    font-size: 12px;
                    left: 0;
                    top: 27px;
                }
            }
        }

        .checkbox-custom {
            border: solid 2px $primary-color;
        }

        .checkbox-label,
        .checkbox-hint {
            font-size: 12px;
            margin: 8px 0px;
        }
        .checkbox-container {
            color: $text-color_80;
        }

        .inputLabel {
            color: $text-color_60;
            font-size: 14px;
            margin-bottom: 2px;
            letter-spacing: 0.45px;
        }

        .column {
            flex-direction: column;
            justify-content: center;
            margin: 4px 0px;
            flex: 1;

            input,
            textarea {
                width: 100%;
                height: 40px;
                box-sizing: border-box;
            }

            > div {
                position: relative;

                p {
                    color: $error-red;
                    text-align: right;
                    position: absolute;
                    content: '';
                    font-size: 12px;
                    width: 100%;
                    left: 0px;
                    top: 30px;
                    letter-spacing: 0;
                }
            }
        }

        .remarks {
            width: 100%;
            letter-spacing: 0.45px;
            color: $text-color_80;
            font-size: 12px;
            margin-top: 0;

            .necessary {
                letter-spacing: 0;
                margin-bottom: 17px;
                color: $text-color_60;
            }
        }
    }

    .dzu-dropzone {
        overflow: hidden;
        width: 100%;
        min-height: 80px;
        border: 1px dashed $primary-color;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        padding: 5px 20px 5px 20px;
    }

    .dzu-inputLabel {
        font-size: 16px;
        letter-spacing: 0.45px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        color: $primary-color;
        padding-left: 35px;
    }

    label.dzu-inputLabel:before {
        content: close-quote;
        position: absolute;
        top: 27px;
        left: 64px;
        background-image: url('/assets/icons/form/upload.svg');
        background-repeat: no-repeat;
        background-size: contain;
        width: 23px;
        height: 30px;
    }

    .dzu-inputLabelWithFiles {
        font-size: 16px;
        border-radius: 0;
        align-self: center;
        margin: 10px 0px 10px 0px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.45px;
        color: $primary-color;
        background-color: transparent;
        border: 1px solid $primary-color;
        text-transform: uppercase;
        color: $base-color;
        background-color: $primary-color;

        &:hover {
            filter: brightness(0.9);
        }
    }

    .sp-preview {
        overflow: hidden;
        width: 100%;
        padding: 10px;
        min-height: 20px;
        background-color: $base-color;
        border-color: $text-color_60;

        .sp-preview-content {
            overflow-wrap: anywhere;
            margin-right: 10px;
            width: 80%;
        }

        //Trash button always visible instead on Mouse over
        .sp-preview-btns {
            visibility: visible;
            position: relative;
            margin: -10px 0px -10px 0px;
            width: 25px;
            height: 25px;
        }
    }

    //Collapsible
    .Collapsible {
        background: $grey-three_25;
        user-select: none;
        margin-bottom: 8px;
    }

    .Collapsible__contentInner {
        padding: 10px;
        border-top: 0;
    }

    .Collapsible__contentInner p {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 20px;
    }

    .Collapsible__contentInner p:last-child {
        margin-bottom: 0;
    }

    .Collapsible__trigger {
        display: block;
        font-weight: 400;
        text-decoration: none;
        color: $text-color;
        position: relative;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        padding: 10px;
    }

    .Collapsible__trigger:hover {
        background: gainsboro;
    }

    .Collapsible__trigger:after {
        font-family: 'FontAwesome';
        content: url(/assets/icons/form/arrow.svg);
        position: absolute;
        right: 20px;
        top: 10px;
        display: block;
        transition: transform 300ms;
    }

    .Collapsible__trigger.is-open:after {
        transform: rotateZ(180deg);
    }

    .Collapsible__trigger.is-disabled {
        opacity: 0.5;
        background-color: grey;
    }
}
