@import '../../../variables.scss';

$track-color: $grey-three;
$thumb-color: $grey-one;

$thumb-radius: 20px;
$thumb-height: 20px;
$thumb-width: 20px;

$track-width: 292px;
$track-height: 1px;

$contrast: 5%;

@mixin track() {
    width: $track-width;
    height: $track-height;
    cursor: pointer;
}

@mixin thumb() {
    height: $thumb-height;
    width: $thumb-width;
    border-radius: $thumb-radius;
    background: $thumb-color;
    cursor: pointer;
}

input[type='range'] {
    -webkit-appearance: none;
    margin: 30px 0 20px;
    width: $track-width;
    background-color: transparent;

    &:focus {
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        @include track();
        background: $track-color;
    }

    &::-webkit-slider-thumb {
        @include thumb();
        -webkit-appearance: none;
        margin-top: (($track-height) / 2) - ($thumb-height / 2);
    }

    &:focus::-webkit-slider-runnable-track {
        background: lighten($track-color, $contrast);
    }

    &::-moz-range-track {
        @include track();
        background: $track-color;
    }
    &::-moz-range-thumb {
        @include thumb();
    }

    &::-ms-track {
        @include track();
        background: transparent;
        border-color: transparent;
        border-width: $thumb-width 0;
        color: transparent;
    }

    &::-ms-fill-lower {
        background: darken($track-color, $contrast);
    }
    &::-ms-fill-upper {
        background: $track-color;
    }
    &::-ms-thumb {
        @include thumb();
    }
    &:focus::-ms-fill-lower {
        background: $track-color;
    }
    &:focus::-ms-fill-upper {
        background: lighten($track-color, $contrast);
    }
}
.slider {
    position: relative;
    margin: 12px 0;

    .slider-input-wrapper {
        position: relative;
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    input[type='number'].slider-shower-size {
        color: #504f4e;
        padding: 0 4px;
        padding-right: 36px;
        width: calc(74px - 36px);
        height: 36px;
        border: solid 1px transparent;
        font-size: 16px;
        text-align: right;
        background-color: transparent;
        box-shadow: inset 0.5px 0.5px 4px 0.5px $grey-three_40;
        r &:focus {
            border: solid 1px transparent;
            outline: none;
        }

        &.error {
            border: 1px solid $error_red;
        }
    }
}

.label-id {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: 0.45px;
}

.label-length {
    font-size: 14px;
    position: absolute;
    top: -5px;
    right: 44px;

    &-unit {
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        display: flex;
        align-items: center;
    }
}
