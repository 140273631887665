// Colors
:root {
    --primary-color: #b8a484;
    --primary-color-hover: #9f8c6e;
}
$primary-color: #b8a484;
$primary-color_10: rgba($primary-color, 0.1);
$primary-color_20: rgba($primary-color, 0.2);
$primary-color-hover: var(--primary-color-hover);

$base-color: white;
$base-color_90: rgba($base-color, 0.9);

$background-grey-out: rgba(0, 0, 0, 0.3);

$grey-one: #979797;
$grey-two: #656565;
$grey-three: #c4c4c4;
$grey-three_20: rgba($grey-three, 0.2);
$grey-three_25: rgba($grey-three, 0.25);
$grey-three_30: rgba($grey-three, 0.3);
$grey-three_40: rgba($grey-three, 0.4);
$grey-three_50: rgba($grey-three, 0.5);
$grey-three_62: rgba($grey-three, 0.62);
$grey-three_70: rgba($grey-three, 0.7);
$grey-four: #747373;
$grey-four_30: rgba($grey-four, 0.3);
$grey-five: #f2f2f2;
$discount: #859793;

$error-red: #c74141;

$text-color: #504f4e;
$text-color_60: rgba($text-color, 0.6);
$text-color_80: rgba($text-color, 0.8);

$text-color-two: #303030;

// Spacings
$padding-medium: 16px;
$padding-large: 24px;

$margin-medium: 16px;
$margin-large: 24px;

$default-scrollbar-width: 15px;
// headers

$header-font-size: 16px;

// Config page
$option-item-space-vertical: 22px;
$option-item-space-horizontal: 24px;
$config-option-content-distance-left-reference: 24px;
$config-option-content-distance-right-reference: 20px;
$config-option-content-distance-top-reference: 40px;

// Config option items padding
$config-option-content-items-distance-top: $config-option-content-distance-top-reference - $option-item-space-vertical;
$config-option-content-items-distance-left: $config-option-content-distance-left-reference -
    $option-item-space-horizontal / 2;
$config-option-content-items-distance-right: $config-option-content-distance-right-reference -
    $option-item-space-horizontal / 2;
$config-option-content-items-distance-bottom: 1px;

$large-option-item-margin-left: 37px - $config-option-content-items-distance-left;
$large-option-item-margin-right: 37px - $config-option-content-items-distance-right;

//Z-Index
$z-index-modal: 10;
$debug-layer: 999999;
