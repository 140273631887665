@import '../../../variables.scss';

.submenu-list {
    flex-grow: 2;
    list-style: none;
    padding: 0;
    margin-top: $margin-large;
    margin-left: $margin-large;
    margin-bottom: 68px;
    min-height: 0px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0;

    .header {
        margin-bottom: 10px;
    }
    li {
        cursor: pointer;
        position: relative;
        text-transform: uppercase;
        width: 290px;
        height: 46px;
        label {
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
        &:hover {
            label {
                color: $text-color-two;
                font-weight: 600;
                padding: auto 0;
            }
        }
    }
}
