@import '../../../variables.scss';

.config-option-page-shower-base {
    padding: 0 $config-option-content-distance-right-reference 0 $config-option-content-distance-left-reference;
    font-size: 14px;
    letter-spacing: 0.07px;
    line-height: 1.36;
    flex-grow: 1;
    color: $text-color-two;
    margin-bottom: 49px;

    .checkbox-label {
        padding-top: 25px;
        padding-left: 37px;
        height: auto;
        margin-left: 0px;
        margin-top: 36px;
        border-top: 1px solid $grey-three;
    }
    .checkbox-hint {
        margin-top: 0px;
        font-size: 18px;
        margin-left: 0px;
        color: $text-color-two;
    }

    .checkbox-label input:checked ~ .checkbox-custom,
    .checkbox-label input:focus ~ .checkbox-custom,
    .checkbox-label .checkbox-custom {
        outline: 0px;
        border-width: 2px;
        border-color: $primary-color;
    }

    .checkbox-label input:checked ~ .checkbox-custom::after {
        border-color: $text-color;
    }
}
