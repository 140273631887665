@import '../../../../variables.scss';

.discount-info {
    position: relative;
    color: white;
    background-color: $discount;
    transition: height 0.45s ease-out;
    height: 121px;
    overflow: hidden;

    &.closed {
        height: 42px;
    }

    &-display {
        position: relative;
        height: 42px;
        cursor: pointer;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.45px;
        &-text {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
        }

        &-caret {
            position: absolute;
            right: 17px;
            top: 50%;
            text-decoration: none;
            transform: translateY(-50%);
            width: 12px;
            height: 5px;
            mask: url('../../../../assets/icons/menu/caret.svg');
            background-color: white;
            mask-size: 100% auto;

            &.opened {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }

    &-disclaimer {
        line-height: normal;
        font-size: 11px;
        padding: 0px 23px 23px 23px;
    }
}
