@import '../../../variables.scss';

.sp-loading {
    height: 4px;
    width: 0;
    background-color: $primary-color;

    &-wrapper {
        background-color: $grey-three;
        margin-bottom: 4px;
    }
}
