@import '../../variables.scss';

.retail-login {
    border-left: 5px solid $primary-color;
    background-color: #f4f9ff;
    padding: 30px;
    width: 500px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    input {
        margin-top: 10px;
        padding: 10px;
        width: 60%;
    }
    button {
        margin-top: 40px;
        padding: 10px;
        width: 30%;
    }
    .login-error-hint {
        padding: 10px;
        margin-bottom: -20px;
        margin-top: 10px;
        background-color: #f940405b;
        height: auto;
    }
}
