@import '../../variables.scss';

.reset-config-screen {
    display: flex;
    height: 100%;
    flex-direction: column;

    &-header {
        font-size: 22px;
        font-weight: 600;
        text-align: center;
        margin-top: 12px;
        text-transform: uppercase;
    }

    &-description {
        text-align: center;
        font-size: 16px;
        margin-top: 30px;
        margin-left: 10%;
        margin-right: 10%;
        justify-content: center;
    }
}
