@import './../../../../../variables.scss';

.sidebuttons-component {
    z-index: 1;
    position: absolute;
    right: 14px;
    display: flex;
    flex-direction: column;

    .sidebutton {
        border: none;
        margin-top: 16px;
        width: 55px;
        height: 49px;
        cursor: pointer;
        &.active {
            border: 2px solid $primary-color;
        }

        img {
            max-width: 65%;
            max-height: 65%;
        }
    }
}
