@import '../../variables.scss';

.ambient-menu-component {
    z-index: 2;
    position: absolute;
    top: 298px;
    right: 14px;
    background-color: $base-color_90;
    width: 225px;

    &::after {
        content: '';
        position: absolute;
        top: -23px;
        right: 14px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 23px solid $base-color_90;
    }

    &-tab-menu {
        border-bottom: 1px solid $grey-four_30;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-item {
            position: relative;
            text-align: center;
            line-height: 23px;
            padding: 8px 0;
            width: 75px;
            color: $text-color;
            cursor: pointer;
            &:first-child {
                margin-left: 8px;
            }
            &:last-child {
                margin-right: 8px;
            }
            &.selected {
                padding-bottom: 5px;
                border-bottom: 3px solid $primary-color;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -6px;
                    left: 0;
                    right: 0;
                    width: 100%;
                    border-bottom: 3px solid $primary-color;
                }
            }
        }
    }

    &-content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 8px 15px;
        &-item {
            width: 53px;
            height: 53px;
            padding: 2px;
            margin: 7px 0;
            position: relative;
            cursor: pointer;
            &.selected {
                box-shadow: 0 4px 10px 0 rgba(157, 157, 157, 0.25);
                &::before {
                    position: absolute;
                    content: '';
                    top: -7px;
                    right: -7px;
                    width: 21px;
                    height: 21px;
                    background-image: url('/assets/icons/common/checkmark.svg');
                    background-color: $primary-color;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 9px;
                    border-radius: 50%;
                }
            }
            &-image {
                width: inherit;
                height: inherit;
                background-size: contain;
            }
        }
    }
}
