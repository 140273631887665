@import '../../variables.scss';
@import './menu-header-content/MenuHeaderContent.component.scss';
@import '../config-option/config-option-measurement-page/configOptionMeasurementPage.component.scss';

$menu-header-height: 98px;
$menu-width: 398px;
$menu-closed-translation: $menu-width - 16px;
$menu-body-color: $base-color_90;
$product-overview-header-height: 56px;

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}
.menu {
    z-index: 1;
    position: fixed;
    display: flex;
    flex-direction: column;
    width: $menu-width;
    height: 100vh;
    max-height: 100%;
    backdrop-filter: blur(5px);
    backface-visibility: hidden;
    animation-duration: 0.3s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-name: closeMenuAnimation;
    color: $text-color;
    letter-spacing: 0.45px;

    &.open {
        animation-name: openMenuAnimation;
    }

    &.hide {
        width: 0;
    }

    &-btn {
        position: absolute;
        cursor: pointer;
        height: 58px;
        width: 0px;
        right: -20px;
        border-left: 20px solid $base-color;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        z-index: 1000;

        .btn-image {
            position: relative;
            transform: translateY(-50%);
            top: 50%;
            left: -20px;
        }
    }

    &-buttons {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100px;
        height: 100px;
        margin: auto;
        &-2 {
            position: absolute;
            top: 200px;
            bottom: 0;
            right: 0;
            width: 100px;
            height: 100px;
            margin: auto;
        }
    }

    &-header {
        display: flex;
        align-items: center;
        background-color: $base-color;
        height: $menu-header-height;
        min-height: $menu-header-height;
        cursor: pointer;
        user-select: none;

        .logo.retailer {
            width: 100%;
            height: 100%;
            margin: 0;
        }

        .logo {
            width: 110px;
            margin: $margin-medium;
            margin-left: $margin-large;
            user-select: none;

            &-wrapper {
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;
            }
        }

        img {
            user-select: none;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - #{$menu-header-height});
        position: relative;
        background-color: $menu-body-color;
        font-size: 18px;
    }
}

@keyframes closeMenuAnimation {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(-$menu-closed-translation, 0, 0);
    }
}

@keyframes openMenuAnimation {
    from {
        transform: translate3d(-$menu-closed-translation, 0, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}
