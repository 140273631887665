@import '../../../variables.scss';

.modal-success-screen {
    display: flex;
    height: 100%;
    flex-direction: column;

    &-thanks {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        margin-top: 12px;
        color: $text-color-two;
        text-transform: uppercase;
        letter-spacing: 1.1pt;
        margin-top: 38px;
    }

    &-description {
        text-align: center;
        font-size: 16px;
        margin-top: 38px;
        letter-spacing: 0.4pt;
        margin-bottom: 36px;
    }
    &-social {
        margin-top: $margin-large;
        text-align: center;
    }

    .cancel-button {
        position: absolute;
        top: 24px;
        right: 24px;
        img {
            height: 25px;
        }
    }
}
