.sp-icon {
    background-image: url('/assets/icons/common/checkmark.svg');
    background-color: $primary-color;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    border-radius: 150%;
    height: 100%;

    &.trash {
        background-color: $base-color;
        background-image: url('/assets/icons/common/trash.svg');
        border-radius: 0;
    }
}
