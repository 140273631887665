@import './variables.scss';
@import '../node_modules/react-dropzone-uploader/dist/styles.css';

.ReactModal__Overlay {
    z-index: $z-index-modal;
}

body {
    overflow: hidden;
    font-family: 'Source Sans Pro', sans-serif !important;
    width: 100%;
    height: 100%;
    -webkit-tap-highlight-color: transparent;
}

a {
    color: $primary-color;
}

a:hover {
    cursor: pointer;
    color: $primary-color-hover;
}

.canvas,
#canvas {
    width: 100%;
    height: 100vh;
    user-select: none;
}

.closeButton {
    position: absolute;
    top: 23px;
    right: 21px;
    height: 49px;
    width: 49px;
    background-color: $base-color;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Inputs / Textareas */

/* Hide little arrows in input[number] */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

input[type='text'],
input[type='number'],
textarea {
    appearance: none;
    height: 25px;
    border: 1px solid $text-color_60;
    padding: 8px;
    border-radius: 0;
    box-shadow: none;

    &:focus {
        outline: solid 1px $text-color_60;
        border: 1px solid $text-color_60;
        border-radius: 0;
    }
}

textarea {
    height: 75px;
}

/* Checkbox */
.checkbox-label {
    display: flex;
    position: relative;
    margin: auto;
    cursor: pointer;
    align-items: center;
    font-size: 22px;
    line-height: 18px;
    width: 90%;
    font-size: 14px;
    padding-left: 34px;
    margin-left: 12px;
    letter-spacing: 0.45px;
    color: $text-color_80;

    &:focus {
        outline: 3px solid blue;
    }
    &.error .checkbox-custom {
        border: solid 1px red;
    }
}

.checkbox-hint {
    letter-spacing: 0.45px;
    color: $text-color_80;
    margin-top: 12px;
    line-height: 18px;
    font-size: 14px;
    margin-left: 12px;
}

.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label .checkbox-custom {
    position: absolute;
    left: 0px;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border-radius: 0;
    transition: all 0.3s ease-out;
    border: solid 1px $text-color_60;
    &:focus,
    &:focus-within,
    &:active {
        outline: 2px solid $primary-color !important;
    }
}

.checkbox-label input:focus ~ .checkbox-custom {
    outline: solid 2px $primary-color;
}

.checkbox-label input:checked ~ .checkbox-custom {
    background-color: $base-color;
    border-radius: 0;
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: solid 1px $text-color_60;
}

.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: '';
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 0;
    border: solid $grey-three;
    border-width: 0 3px 3px 0;
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 6px;
    top: 1px;
    width: 6px;
    height: 12px;
    border: solid $primary-color;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
}

/* Checkbox Ripple Effect */
.checkbox-label .checkbox-custom::before {
    position: absolute;
    content: '';
    left: 10px;
    top: 10px;
    width: 0px;
    height: 0px;
    border-radius: 0;
    border: solid 1px $text-color_60;
    transform: scale(0);
}

.checkbox-label input:checked ~ .checkbox-custom::before {
    left: -3px;
    top: -3px;
    width: 24px;
    height: 24px;
    border-radius: 0;
    transform: scale(3);
    opacity: 0;
    z-index: 999;
    transition: all 0.3s ease-out;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
    background-color: $grey-three;
}

//Babylon JS Debug Inspector
#scene-explorer-host,
#inspector-host {
    z-index: $debug-layer;
}
