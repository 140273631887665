@import '../../../variables.scss';

.onboarding-dialog {
    position: absolute;
    max-width: 90%;
    box-sizing: border-box;
    width: 452px;
    min-width: 452px;
    height: 193px;
    min-height: 193px;
    height: auto;
    color: $text-color-two;
    background-color: $base-color;
    top: 80%;
    left: 60%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    &-header-wrapper {
        width: 100%;
    }

    &-header {
        font-weight: 600;
        font-size: 18px;
        display: flex;
        padding: 16px;
        padding-top: 15px;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid $grey-three;

        &.finished {
            border-bottom: none;
            padding-bottom: 0px;
        }

        button {
            background: none;
            border: none;
            padding: 0;
            cursor: pointer;
        }

        .next-button {
            margin-left: auto;
            color: $primary-color;
        }

        .tutorial-headline {
            margin-left: 35px;
        }

        .close-button {
            width: 12px;
            height: 12px;
            margin-right: auto;
            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        .finish-headline {
            margin-left: -15px;
            margin-right: auto;
            font-size: 25px;
            font-weight: 600;
        }
    }

    .highlightet-text {
        font-weight: 600;
    }

    &-content {
        color: $text-color;
        text-align: center;
        line-height: 1.31;
        letter-spacing: 0.5px;
        font-size: 16px;
        padding: 24px;
        padding-top: 18px;

        .finish-text {
            margin-bottom: 16px;
        }
    }

    &-footer {
        padding-top: 24px;
    }
}
