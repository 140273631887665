@import '../../variables.scss';

.button-component {
    min-width: 56px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.45px;
    box-sizing: border-box;
    white-space: nowrap;
    border: 2px solid $primary-color;
    cursor: pointer;

    &.light {
        height: 60px;
        color: $primary-color;
        background-color: transparent;
        text-transform: uppercase;

        &:hover {
            border-color: $primary-color-hover;
            color: $primary-color-hover;
        }

        &:disabled {
            border-color: $grey-three;
            color: $grey-three;
            cursor: unset;
        }
    }

    &.dominant {
        height: 60px;
        color: $base-color;
        background-color: $primary-color;
        text-transform: uppercase;

        &:hover {
            background-color: $primary-color-hover;
            border-color: $primary-color-hover;
        }

        &:disabled {
            background-color: $grey-three;
            border-color: $grey-three;
            cursor: unset;
        }
    }

    &.soft {
        height: 50px;
        color: $base-color;
        background-color: $primary-color;

        &:hover {
            background-color: $primary-color-hover;
            border-color: $primary-color-hover;
        }

        &:disabled {
            background-color: $grey-three;
            border-color: $grey-three;
            cursor: unset;
        }
    }
}
