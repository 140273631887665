@import '../../../variables.scss';

.step-indicator {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .bullet {
        background: $grey-three;
        border-radius: 50%;
        display: inline-block;
        width: 8px;
        height: 8px;
        margin: 0 3px;
        transition: background 0.15s ease;
        cursor: pointer;

        &.active {
            background: $primary-color;
        }
    }
}
