@import '../../../variables.scss';

.sp-config-option-item {
    position: relative;
    width: 100px;
    display: flex;
    flex-direction: column;
    height: 110px;
    align-items: center;
    background-color: $base-color;
    cursor: pointer;
    margin: $option-item-space-vertical $option-item-space-horizontal/2 0px $option-item-space-horizontal/2;

    &-number {
        font-weight: 600;
    }

    &-image {
        padding-top: 10px;
        height: 69px;
        width: 60px;
        object-fit: contain;
        margin: auto;
        display: flex;
        justify-content: center;
    }

    .sp-config-option-item-label {
        width: 100%;
        text-align: center;
        font-size: 14px;
        height: 20px;
    }

    &.medium {
        width: 158px;
        height: auto;

        .sp-config-option-item-number-image-content {
            padding-top: 0;
        }

        .sp-config-option-item-image {
            height: 83px;
            width: auto;
            padding-top: 0;
            margin-bottom: 9px;
            margin-top: 19px;
        }

        .sp-config-option-item-label {
            margin-bottom: 8px;
        }
    }

    &.large {
        width: 100%;
        margin: 0px $large-option-item-margin-right 25px $large-option-item-margin-left;
        height: auto;

        &:first-child {
            margin-top: $config-option-content-items-distance-top;
        }

        .sp-config-option-item-number-image-content {
            position: relative;
            overflow: hidden;
            padding-top: 0;

            .series-infotext-area {
                position: absolute;
                width: auto;
                padding: 21px;
                height: 100%;
                font-size: 15px;
                line-height: 22px;
                letter-spacing: 0.45px;
                background: rgba(255, 255, 255, 0.9);
                top: 0px;
                transition: top 1s;

                &.closed {
                    top: 221px;
                }
            }
        }

        .sp-config-option-item-image {
            object-fit: fill;
        }

        label {
            display: none;
        }

        .sp-config-option-item-image {
            height: 221px;
            width: 100%;
            padding-top: 0;
        }

        .sp-config-option-item-label {
            display: flex;
            width: 100%;
            text-align: center;
            font-size: 16px;
            color: $primary-color;
            height: 37px;
            line-height: 37px;
            text-transform: uppercase;

            .left-column {
                flex: 1 1 0;
            }
            .description {
                flex: 3 1 0;
            }
            .right-column {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                flex: 1 1 0;
                .info {
                    margin-right: 16px;
                    width: 14px;
                    height: 14px;
                    fill: $primary-color;
                    &.closed {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &.small-with-label {
        .sp-config-option-item-image {
            padding-top: 0px;
        }
        .sp-config-option-item-label  {
            padding-bottom: 5px;
        }
    }
    label {
        position: absolute;
        content: '';
        top: 4px;
        left: 6px;
        letter-spacing: 0.45px;
        color: $grey-two;
        font-size: 16px;
    }

    &-selected-label {
        position: absolute;
        content: '';
        top: -10px;
        right: -10px;
        width: 24px;
        height: 24px;
        background-image: url('/assets/icons/common/checkmark.svg');
        background-color: $primary-color;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
    }
    &.selected {
        outline: 1.5px solid $primary-color;
    }
    &.outline {
        outline: 1px solid $grey-three;
    }

    &-number-image-content {
        padding-top: 16px;
        width: 100%;
    }

    &.unavailable &-number-image-content {
        filter: blur(1px);
        -webkit-filter: blur(1px);
    }

    &-beam-max-length {
        position: absolute;
        bottom: 28px;
        left: 50%;
        transform: translateX(-50%);

        color: $text-color-two;
        letter-spacing: 0.45px;
        font-size: 12px;
    }

    &.unavailable &-beam-max-length {
        color: $error-red;
    }

    &.unavailable {
        cursor: initial;
        background-color: $grey-three_40;
    }
}
