.toggle-slider-component {
    display: flex;
    align-items: center;
    height: auto;
    user-select: none;

    :hover {
        cursor: pointer;
    }

    span {
        padding-left: 20px;
        font-size: 14px;
    }
}
