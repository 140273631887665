@import '../../../variables.scss';

.info-dialog-component {
    position: relative;
    display: inline-flex;
    cursor: initial;

    .background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 1001;
        visibility: hidden;
        transition: all 0.1s ease-out;

        &.show {
            background-color: rgba(black, 0.05);
            visibility: visible;
        }
    }

    .info-icon {
        cursor: pointer;
    }

    .dialog {
        position: absolute;
        top: 7px;
        transform: translateY(-50%);
        left: 40px;
        font-size: 14px;
        white-space: normal;
        width: 300px;
        z-index: 1002;
        visibility: hidden;
        opacity: 0;
        transition: all 0.2s ease-out;
        box-shadow: 0px 2px 10px 0 $grey-three_70;

        &.show {
            visibility: visible;
            opacity: 1;
        }

        &::before,
        ::after {
            content: '';
            position: absolute;
            box-sizing: border-box;
            top: calc(50% - 10px);
            background: $grey-five;
            transform: rotate(135deg) skew(10deg, 10deg);
            z-index: -1;
            width: 20px;
            height: 20px;
            left: -10px;
        }

        &::before {
            box-shadow: 0px 2px 10px 0 $grey-three_70;
        }

        .content {
            padding: 12px 20px;
            text-align: left;
            background-color: $grey-five;
            letter-spacing: 0.45px;
            line-height: 18px;
            font-weight: 500;
            color: $text-color-two;
        }
    }
}
