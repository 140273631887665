@import '../../../variables.scss';

.sub-menu-component {
    width: 100%;
    height: auto;
    padding: 0px 36px;
    background-color: $primary-color_10;
    box-sizing: border-box;
    margin-top: 24px;

    p {
        line-height: 19px;
        letter-spacing: 0.07px;
        color: $text-color-two;
        font-size: 14px;
    }
}
